export default {
    row: {
      display: [`block`, `block`, `flex`],
      flexWrap: `wrap`,
      mb: 3
    },
    column: {
      flex: `auto`,
      mb: [3, 3, 0],
      '+ div': {
        ml: [0, 0, 3]
      }
    },
    input: {
      border: `none`,
      bg: `omegaLighter`,
      outline: `none`,
      '::placeholder': {
        color: `omega`
      }
    },
    textarea: {
      variant: 'forms.input',
      fontSize: 3,
      minHeight: 200
    },
    label: {
      mb: 2
    }
  }
  